import { locoScroll, isTouch } from '../base/scroll.js'

export const initHeader = () => {
	const trigger = document.querySelector('.header__trigger')
	const header = document.querySelector('.header')
	let open = false
	if (trigger) {
		trigger.addEventListener('click', () => {
			trigger.classList.toggle('active')
			header.classList.toggle('active')
			open = !open
			if (!isTouch()) {
				if (open) {
					locoScroll.stop()
				} else {
					locoScroll.start()
				}
			} else {
				const body = document.querySelector('body')
				if (open) {
					body.classList.add('overflow-hidden')
				} else {
					body.classList.remove('overflow-hidden')
				}
			}
		})
	}
}
