<template>
  <div data-scroll-container class="smooth-scroll">
    <main>
      <section data-scroll-section class="simple-header color--light">
        <div class="large-header__gradient gradient">
          <svg
            viewBox="0 0 1144 964"
            style="
              transform: translate3d(1.4383vw, 25.211vh, 0px) rotate(-2.7399deg)
                scale(1.0523, 1.4778);
            "
          >
            <radialGradient id="lh-gradient" x2="0.35" y2="1">
              <stop offset="0%" stop-color="var(--color-start)"></stop>
              <stop offset="50%" stop-color="var(--color-start)"></stop>
              <stop offset="73%" stop-color="var(--color-middle)"></stop>
              <stop offset="100%" stop-color="var(--color-end)"></stop>
            </radialGradient>
            <filter id="blur">
              <feGaussianBlur
                in="SourceGraphic"
                stdDeviation="20"
              ></feGaussianBlur>
            </filter>
            <circle
              cx="580"
              cy="580"
              r="580"
              fill="url(#lh-gradient)"
              fill-rule="evenodd"
              filter="url(#blur)"
              transform="translate(124 -320)"
            ></circle>
          </svg>
        </div>
        <div class="simple-header__content">
          <div class="simple-header__inner">
            <h1 data-scroll class="simple-header__title xxlarge-heading">
              <b>Create a personal</b><br>Growing Minds email signature.
            </h1>
          </div>
        </div>
        <section class="form  color--dark" data-scroll>
          <Form />
        </section>
      </section>
    </main>
  </div>
</template>

<script>
import Form from "../form/Form.vue";
export default {
  data: function () {
    return {
      mode: process.env.NODE_ENV,
    };
  },
  components: {
    Form,
  },
  methods: {
    clone () {
      window.prompt("Copy to clipboard: Ctrl+C, Enter", this.$refs.element.outerHTML);
      // this.$refs.element.cloneNode(true)
    }
  },
};
</script>
