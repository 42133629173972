/**
 * Utility object with all the value-transform-methods
 *
 * @author Tim van Lent <tim@growingminds.nl>
 * @copyright Growing Minds BV 2020
 */

/**
 * Constructor
 */
const Transformers = function () {

  /**
   * Method to transform values to be upper case
   * @param   string raw value
   * @returns string upper case value
   */
  this.upperCase = value => {
    return value.toUpperCase();
  };

  /**
   * Method to remove spaces between strings
   * @param   string raw value
   * @returns string with removed spaces
   */
  this.removeSpaces = value => {
    return value.replace(/\s/g, '');
  };

  /**
   * Method to transform values to be lower case
   * @param   string raw value
   * @returns string lower case value
   */
  this.lowerCase = value => {
    return value.toLowerCase();
  };

  /**
   * Method to transform values to be capitalized
   * @param   string raw value
   * @returns string capitalized value
   */
  this.capitalize = value => {
    return value.replace(/(^\w{1})|(\s{1}\w{1})/g, match => match.toUpperCase());
  };

  /**
   * Method to transform a birth date
   * @param   string A (partial) birth date
   * @param   Event  the event object
   * @returns string transformed value
   */
  this.birthDate = (value, e) => {

    // after two digits append a dash
    if ((value.match(/^(\d{2}|\d{2}-\d{2})$/g)) && e.keyCode !== 8) return value + '-';

    // removes double dashes from input
    return value.replace('--', '-');
  };

  /**
   * Method to transform initials
   * @param   string raw value
   * @returns string transformed initials
   */
  this.initials = value => {
    value = value.replace(/[ _.*;:()#$%^&'"/\\,<>~0-9!?\-=+@\t]/g, "");

    if (value === '') return '';

    return value === '' ? value : value.split('').join('.').toUpperCase() + '.';
  };

  /**
   *  @param  string amount value
   *  @retuns string transformed amount
   */
  this.amount = value => {
    
    // split up by dot
    const parts = value.replace(',', '.').split('.');
    
    // return value if there are no delimiters
    if (parts.length === 1) return value;

    // remove all dots except the last one
    value = parts.slice(0, -1).join('') + '.' + parts.slice(-1);

    // set two floating numbers and delimit with comma
    return parseFloat(value).toFixed(2);
  };
};

// export instance
export default new Transformers();