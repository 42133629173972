import '../assets/scss/app.scss';

import { createApp } from 'vue'
import App from '../views/App1.vue'

import 'core-js/stable'

import { initHeader } from '../plugins/elements/header.js'
import { initFooter } from '../plugins/elements/footer.js'
import { initScroll } from '../plugins/base/scroll.js'

import { initLazyLoad } from '../plugins/base/lazy-load.js'

import { initGradientMovement } from '../plugins/elements/gradient-movement.js'
import { initServiceSlider, initRelatedSlider } from '../plugins/elements/sliders.js'
import { initVideoControls } from '../plugins/elements/video-controls.js'
import { initCounter } from '../plugins/elements/counter.js'
import { initFixedCTA } from '../plugins/elements/fixed-cta.js'

const initApp = () => {
  initScroll()
  initLazyLoad()

  initHeader()
  initFooter()
  initGradientMovement()

  initServiceSlider()
  initRelatedSlider()
  initVideoControls()
  initCounter()
  initFixedCTA()
}

document.addEventListener('DOMContentLoaded', initApp)

createApp(App).mount('#app')